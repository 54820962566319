import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Article} from '@worxs/cms/types/article';
import {ArticleSearch} from '../types/article-search';
import {QueryStringService} from './querystring.service';
import {environment} from '../../environments/environment';
import {map, tap} from "rxjs/operators";
import {StrapiResponse} from "@strapi/types/strapi-response";
import {Service} from "@worxs/cms/types/service";
import {ArticleTypeService} from "./article-type.service";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  public readonly pageSize = 9;
  private articles$: BehaviorSubject<Article[]> = new BehaviorSubject<Article[]>([]);
  private path = '/articles';
  private defaultPopulate = 'article_types,services,image,additional.video'

  constructor(
    private http: HttpClient,
    private qs: QueryStringService,
    private articleTypeService: ArticleTypeService
  ) {
  }

  getArticles(limit?: number, service?: Service): Observable<StrapiResponse<Article[]>> {
    const query = {} as ArticleSearch;
    if (limit) {
      query.limit = limit;
    }

    if (service) {
      query.service = service;
    }

    return this.search(query);
  }

  getArticle(id: number): Observable<Article> {
    const url = `${environment.backendEndpoint + this.path}/${id}?populate=${this.defaultPopulate}`;

    return this.http.get<{ data: Article }>(url)
      .pipe(
        map(response => response.data),
        tap(article => {
          this.upsertArticle(article);
        })
      );
  }

  /**
   * Update the article in the local store. If it doesn't exist, add it.
   * @param article
   * @private
   */
  private upsertArticle(article: Article): void {
    const currentArticles = this.articles$.value;
    const index = currentArticles.findIndex(a => a.id === article.id);

    if (index >= 0) {
      currentArticles[index] = article;
      this.articles$.next(currentArticles);
    } else {
      this.articles$.next([...currentArticles, article]);
    }
  }

  search(query: ArticleSearch): Observable<StrapiResponse<Article[]>> {
    const queryString = this.qs.stringify({
      filters: {
        $or: [
          {
            title: {
              $containsi: query.free_text
            },
            article_types: {
              id: {
                $in: query.articleType?.id
              }
            },
            services: {
              id: {
                $in: query.service?.id
              }
            }
          },
          {
            abstract: {
              $containsi: query.free_text
            },
            article_types: {
              id: {
                $in: query.articleType?.id
              }
            },
            services: {
              id: {
                $in: query.service?.id
              }
            }
          },
          {
            article_types: {
              id: {
                $in: query.articleType?.id
              }
            },
            services: {
              id: {
                $in: query.service?.id
              }
            }
          },
          {
            slug: {
              $eq: query.slug
            }
          }
        ],
      },
      sort: {publishedAt: 'desc'},
      pagination: {
        limit: query.limit ? query.limit : this.pageSize,
        start: (query.page ? query.page * this.pageSize : 1) - this.pageSize,
      },
    });

    const url = `${environment.backendEndpoint + this.path}?${queryString}&populate=${this.defaultPopulate}`;

    return this.http.get<StrapiResponse<Article[]>>(url)
      .pipe(
        this.processArticleTypes()
      );
  }

  /**
   * Loops through each articles article_types and adds donut attributes.
   * @private
   */
  private processArticleTypes() {
    return map((response: StrapiResponse<Article[]>) => {
      for (const article of response.data) {
        if (article.attributes?.article_types?.data) {
          for (let articleType of article.attributes.article_types.data) {
            articleType = this.articleTypeService.addDonutAttributes(articleType);
          }
        }
      }

      return response;
    });
  }

  getArticleBySlug(slug: string): Observable<Article> {
    return this.search({slug: slug})
      .pipe(map(response => response.data[0]))
  }
}
