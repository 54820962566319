import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ArticleType} from '@worxs/cms/types/article-type';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ArticleTypeService {

  articleTypes$: BehaviorSubject<ArticleType[]> = new BehaviorSubject<ArticleType[]>([]);
  articleTypes?: ArticleType[];

  constructor(
    private http: HttpClient
  ) {
  }

  fetchArticleTypes(): Observable<ArticleType[]> {
    return this.http.get<{ data: ArticleType[] }>(environment.backendEndpoint + '/article-types')
      .pipe(
        map(response => response.data),
        map(articleTypes => {
          return articleTypes.map(articleType => {
            articleType.attributes.color = this.getArticleTypeColor(articleType.id);
            articleType.attributes.donutClass = 'donut-' + articleType.attributes.color;
            return articleType;
          });
        }),
        tap(articleTypes => this.articleTypes$.next(articleTypes))
      )
  }

  getArticleTypeColor(typeId: number): string {
    switch (typeId) {
      case 1:
      case 5:
        return 'light-blue';
      case 2:
      case 6:
        return 'green';
      case 3:
      case 7:
        return 'red';
      case 4:
      case 8:
        return 'blue';
    }

    return '';
  }

  fetchArticleType(typeId: number): Observable<ArticleType> {
    const articleType = this.articleTypes$.value.filter(articleType => articleType.id === typeId)[0];
    if (articleType) {
      return of(articleType);
    }

    return this.http.get<{ data: ArticleType }>(environment.backendEndpoint + '/article-types/' + typeId)
      .pipe(
        map(response => response.data),
        map(articleType => {
          articleType.attributes.color = this.getArticleTypeColor(articleType.id);
          articleType.attributes.donutClass = 'donut-' + articleType.attributes.color;
          return articleType;
        }),
        tap(articleType => {
          const currentArticleTypes = this.articleTypes$.value;
          currentArticleTypes.push(articleType);

          const existingArticleType = currentArticleTypes.find((articleType) => articleType.id === typeId);
          if (existingArticleType) {
            currentArticleTypes.splice(currentArticleTypes.indexOf(existingArticleType), 1);
          }

          this.articleTypes$.next(currentArticleTypes);
        })
      )
  }

  async getArticleTypeFromId(typeId: number): Promise<ArticleType> {
    await this.fetchArticleTypes();

    let selectedArticleType: ArticleType = {id: 0, attributes: {name: 'Undefined', slug: '', articles: []}};

    if (this.articleTypes) {
      for (const articleType of this.articleTypes) {
        if (articleType.id === typeId) {
          selectedArticleType = articleType;
        }
      }
    }

    return selectedArticleType;
  }

  addDonutAttributes(articleType: ArticleType): ArticleType {
    articleType.attributes.color = this.getArticleTypeColor(articleType.id);
    articleType.attributes.donutClass = 'donut-' + articleType.attributes.color;

    return articleType;
  }
}
