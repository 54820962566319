import {Injectable} from '@angular/core';
import * as Querystring from 'qs';

@Injectable({
  providedIn: 'root'
})
export class QueryStringService {
  private querystring = Querystring;

  constructor() {
  }

  public stringify(input: any): string {
    return this.querystring.stringify(input);
  }

  public parse(input: string): any {
    return this.querystring.parse(input);
  }
}
