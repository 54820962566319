import {Injectable} from "@angular/core";

@Injectable()
export class UrlReplacerService {

    constructor() {
    }

    replaceHost(value: string, host: string): string {
      return value.replace(/src="\//gmi, "src=\"" + host + "/");
    }
}
